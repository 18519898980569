@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=bda2e1d5-4ffe-47a5-9dc9-7cd4a902882b");

  @font-face{
      //LT W01_35 Light1475496
      font-family:"Avenir_font";
      src:url("/../fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix");
      src:url("/../fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix") format("eot"),url("/../fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("/../fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff"),url("/../fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf") format("truetype");
      font-weight: 100;
      font-style: normal;
  }

  @font-face {
      //LT W01_45 Book1475508
      font-family:"Avenir_font";
      src:url("/../fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix");
      src:url("/../fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix") format("eot"),url("/../fonts/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),url("/../fonts/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),url("/../fonts/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype");
      font-weight: 200;
      font-style: normal;
  }

  @font-face{
      //LT W01_45 Book O1475514"
      font-family:"Avenir_font";
      src:url("/../fonts/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix");
      src:url("/../fonts/d8c50fcc-bc32-4f31-8c24-b170c64e2254.eot?#iefix") format("eot"),url("/../fonts/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2") format("woff2"),url("/../fonts/f1ebae2b-5296-4244-8771-5f40e60a564a.woff") format("woff"),url("/../fonts/04d1bf6c-070d-4b7e-9498-6051c9f0c349.ttf") format("truetype");
      font-weight: 300;
      font-style: italic;
  }

  @font-face {
      //LT W01_65 Medium1475532
      font-family:"Avenir_font";
      src:url("/../fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix");
      src:url("/../fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix") format("eot"),url("/../fonts/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"),url("/../fonts/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff"),url("/../fonts/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf") format("truetype");
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
      // LT W01_65 Medium1475532
      font-family:"Avenir_font";
      src:url("/../fonts/cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix");
      src:url("/../fonts/cebcf56a-ea7e-4f5c-8110-a7ee367c1f91.eot?#iefix") format("eot"),url("/../fonts/deb5e718-7abb-4df3-9365-edfa95317090.woff2") format("woff2"),url("/../fonts/04801919-17ee-4c6b-8b17-eb1965cb3ed6.woff") format("woff"),url("/../fonts/cee053ec-4b41-4506-a1fe-796261690610.ttf") format("truetype");
      font-weight: 500;
      font-style: italic;
  }

  @font-face {
      //LT W01_85 Heavy1475544
      font-family:"Avenir_font";
      src:url("/../fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
      src:url("/../fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"),url("/../fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),url("/../fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"),url("/../fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype");
      font-weight: 600;
      font-style: normal;
  }

  @font-face {
      //LT W01_95 Black1475556
      font-family:"Avenir_font";
      src:url("/../fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix");
      src:url("/../fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix") format("eot"),url("/../fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),url("/../fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff"),url("/../fonts/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf") format("truetype");
      font-weight: 700;
      font-style: normal;
  }
