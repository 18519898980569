
///////////////////////////////////////////////////////
//////////////-----Background-header-----//////////////
///////////////////////////////////////////////////////

.full-width-header {
  width: 100%;
  height: 100vh;
  position: relative;
  min-height:500px;
}

@media (max-width:1150px){
  .full-width-header {
  min-height:780px;
  }
}

.explore-helper {
  position: absolute;
  bottom: 35px;
  color: $light;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.explore-helper__icon {
  width: 28px;
  height: 14px;
  background-image: url(/../images/explore_down.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.full-width-header .navbar {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
}

.full-width-header .navbar-collapse {
  &.show {
    padding: 20px;

    background-color: rgba(0, 0, 0, 0.7);
    border-radius: $border-radius;
    border: 2px solid white;
  }

  &.collapsing {
    padding: 20px;

    background-color: rgba(0, 0, 0, 0.7);
    border-radius: $border-radius;
    border: 2px solid white;
  }
}

.navbar-toggler-icon {
  opacity: 1;
}

.full-width-header .nav-item .nav-link {
  color: $light;
}

.full-width-header .nav-item .nav-link:hover {
  color: $light;
  text-decoration: underline;
}

.full-width-header .navbar__alignright .nav-link {
  color: $light;
}

.full-width-header .navbar__alignright .nav-link:hover {
  color: $light;
  text-decoration: underline;
}

.header-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-flow:column nowrap;
  justify-content: center;
  align-items: center;
}

.full-width-header .header-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0 $gutter;
}

.full-width-header .header-text .text {
  color: $light;
  text-align: center;
  margin-bottom: 40px;
}

@media (max-width:600px){
  .full-width-header .header-text .text h1{
    font-size:30px;
  }
  .full-width-header .header-text .text p{
    font-size:15px;
  }
}

.full-width-header .filter {
  width: 1150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width:1199.98px) {
  .full-width-header .filter {
    width:90%;
  }
}

//Image settings//

.full-width-header .image-container {
  width: calc(100% + 30px);
  height: 100%;
  position: relative;
  //border:1px solid yellow;
  width:100%;
  margin-bottom: 15px;
}

.full-width-header .image-container .img { //used for cropping image
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: $tertiary;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  display:flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}
