
///////////////////////////////////////////////////////
///////////////////-----Stars-----/////////////////////
///////////////////////////////////////////////////////

.stars {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 90px;
  min-width: 95px;
}

.star {
  width: 15px;
  height: 15px;

  background-repeat: no-repeat;
  background-image: url(/../../images/rating_star_1.svg);
}

.star_no {
  width: 15px;
  height: 15px;

  background-image: url(/../../images/rating_star_0.svg);
}
