
///////////////////////////////////////////////////////
///////////////////-----Header-----////////////////////
///////////////////////////////////////////////////////

.navbar {
  font-weight: 700;
}

.navbar-brand {
  padding-right: 3.188rem
}

.navbar .nav-link:hover {
  color: $navbar-light-hover-color;
}

.navbar__alignright {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}



///////////////////////////////////////////////////////
////////////////-----Media queries-----////////////////
///////////////////////////////////////////////////////

@include media-breakpoint-down(md) {
  .navbar__alignright {
    flex-direction: column;
    align-items: flex-start;
  }
}
