
///////////////////////////////////////////////////////
//////////////-----Small-Card Result-----//////////////
///////////////////////////////////////////////////////

.smallcard-grid {
  margin-bottom: 30px;
}

.smallcardresult {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
}

.smallcardresult a {
  display: block;

  text-decoration: none;
}

.smallcardresult a:hover {
  text-decoration: none;
}

.smallcardresult h3 {
  height: 20px;
  overflow: hidden;
  color: $darktext;
}

.smallcardresult p {
  height: 70px;
  margin: 0;
  overflow: hidden;
  margin-bottom: $gutter;
  color: $darktext;
}

//Image settings//

.smallcardresult .image-container {
  width: 100%;
  height: 250px;
  position: relative;
}

.smallcardresult .image-container .img { //used for cropping image
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: $tertiary;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

// Like //
.smallcardresult .image-container .like_button {
  width: 40px;
  height: 40px;
  background-image: url(/../../images/icons/like.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.smallcardresult .image-container .like_button:hover {
  cursor: pointer;
  background-image: url(/../../images/icons/like_white.svg);
}

.smallcardresult .image-container .like_button_liked {
  width: 40px;
  height: 40px;
  background-image: url(/../../images/icons/like_white.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.smallcardresult .image-container .like_button_liked:hover {
  cursor: pointer;
  background-image: url(/../../images/icons/like_white.svg);
}


///////////////////

.content-container {
  height: calc(100% - 250px);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.smallcardresult .bestseller {
  width: 100px;
  height: 23px;
  font-size: 0.75rem;
  background-color: #95BBC1;
  border-radius: $border-radius;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.best-star-wrapper {
  width: 100%;
  height: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.smallcardresult .pricetags-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
}

.smallcardresult .price-tags-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin-bottom: $gutter;
}

.smallcardresult .pricetag {
  display: inline;
  height: 24px;
  font-size: $h2-font-size;
  font-weight: $font-weight-extrabold;
  margin-left: auto;
  width: 400px;
  text-align: right;
  padding-right: 5px;
  color: $darktext;
}

.smallcardresult .tags {
  width: 100%;
  display: flex;
  flex-direction: column;

  margin: 0px;
  font-weight: $font-weight-medium;
  font-style: italic;
}

.smallcardresult .tags .time, .date {
  height: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.smallcardresult .tags .time__icon, .date__icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
}

.smallcardresult .tags .time__icon {
  background-image: url(/../../images/icons/time.svg);
}

.smallcardresult .tags .date__icon {
  background-image: url(/../../images/icons/date.svg);
}

.smallcardresult .btn-primary {
  width: 100%;
}
