
///////////////////////////////////////////////////////
///////////////////-----Reviews-----///////////////////
///////////////////////////////////////////////////////

.reviews {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.reviews__text {
  font-style: italic;
}
