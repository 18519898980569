
///////////////////////////////////////////////////////
////////////////-----card-benefit-----/////////////////
///////////////////////////////////////////////////////

.card-benefit {
  width: 100%;
  height: 333px;
  background-color: #E9EDEE;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 27px;
  margin-bottom: 30px;
}

.card-benefit h2 {
  margin-bottom: 20px;
}
