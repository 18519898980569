
///////////////////////////////////////////////////////
////////////////////-----Modal-----////////////////////
///////////////////////////////////////////////////////

@media (max-width: 575.98px) {
  // .modal[style] {
  //   padding-right: 0px !important;
  // }
}

@media (min-width: 576px) {
  .modal {
    padding-right: 0px;
  }
}

.modal-header {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.modal-header .close {
    padding: 1rem;
    position: absolute;
    right: 15px;
    top: 25px;
}

.modal-content {
  min-width: 100%;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
  }
}
