
///////////////////////////////////////////////////////
////////////////-----card-itinerary-----/////////////////
///////////////////////////////////////////////////////

.card-itinerary {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.card-itinerary img {
  margin-bottom: 20px;
}

.card-itinerary td {
  background: #fff;
}
