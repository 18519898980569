/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

// HTML, BODY
html {
  font-size: 16px;
  color: #000A18;
}

// BODY
body {
  line-height: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// RESPONSIVE MEDIA
img,
embed,
object,
video {
  // max-width: 100%;
}

///////////////////////////////////////////////////////
//////////////-----Global classes-----/////////////////
///////////////////////////////////////////////////////

.temp-addspace-now {
  margin-bottom: 30px;
}

.nav-title {
  display: block;
  padding: 0.5rem 1rem;
  font-weight: $font-weight-bold;
}

.nav-about {
  padding-left: 0px;
}

.nav-languages {
  padding-top: 5px;

  width: 50px;
}

.a--btn {
  width: 100%;
  display: flex;
}

.paragraph--sm {
  font-size: $font-size-sm;
}

.paragraph--lg {
  font-size: $font-size-lg;
}

.paragraph--xl {
  font-size: $font-size-xl;
  font-weight: $font-weight-medium;
}


/*
|--------------------------------------------------------------------------
| Validation
|--------------------------------------------------------------------------
*/

.validation-error {
  margin-top: 2px;
  margin-bottom: 5px;

  color: red;
}

.custom-select.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, $tertiary url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat !important;
}

.custom-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, $tertiary url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat !important;
}
