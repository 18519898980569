
///////////////////////////////////////////////////////
/////////////////-----Collapsible-----/////////////////
///////////////////////////////////////////////////////

.collapsible {
  border-radius: $border-radius;
  margin-bottom: 30px;
  border: 1px solid $border-grey;
  overflow: hidden;
}

.collapsible .accordion .card {
  border: none;
}

.collapsible .accordion .card .card-header {
  height: 60px;
  background-color: $gray-300;
  border-radius: 0;
  border-bottom: none;
  padding: $gutter;
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
}

.collapsible .accordion .card .card-header .groupup {
  display: flex;
}

.collapsible .accordion .card .day {
  font-size: $h3-font-size;
  font-weight: $font-weight-bold;
  margin-right: 35px;
}

.collapsible .accordion .card .title {
  font-size: $h3-font-size;
  font-weight: $font-weight-normal;
}

.collapsible .accordion .card .fictional-button {
  width: 143px;
  font-size: $h3-font-size;
  font-weight: $font-weight-bold;

  margin-right: 0px;

  background-position: right top 5px;
  background-repeat: no-repeat;
  background-image: url(/../../images/arrow_down.svg);
}

.collapsible .accordion .card  .card-header[aria-expanded="true"] .fictional-button {
  background-image: url(/../../images/arrow_up.svg);
}

.collapsible .accordion .card-body {
  padding: 0 1.25rem 0 1.25rem;
}

.collapsible .accordion .card_body--align {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1.25rem 0 1.25rem 0;
}

.collapsible .accordion .card-body .border-add {
  width: 100%;
  border-top: 1px solid #E3E6E8;
}

.collapsible .accordion .card:nth-child(odd) .border-add{
  display: none;

}

///////////////////////////////////////////////////////
///////////////-----Imagegallery_1-----////////////////
///////////////////////////////////////////////////////

.collapsible .carousel {
  width: 402px;
  height: 268px;
}
//
// .collapsible .carousel img {
//   max-width: none;
// }
//
// .collapsible .carousel-item {
//   width: 100%;
// }

.carousel-textarea {
  padding: 18px;
}

.carousel {

}

.carousel-control-prev, .carousel-control-next {
  opacity: 1;
}

.carousel-control-prev-icon, .carousel-control-next-icon  {
  width: 50px;
  height: 50px;
  color: $light !important;
  border-radius: 100px;
  background-size: 16px;
}

.carousel-control-prev-icon {
  background-color: rgba(1,1,1,0.2);
  background-position: center left 14px;
  background-image: url(/../../images/slide-show-arrow-left.svg);
}

.carousel-control-next-icon {
  background-color: rgba(1,1,1,0.2);
  background-position: center right 14px;
  background-image: url(/../../images/slide-show-arrow-right.svg);
}

.carousel-control-prev-icon:hover {
  background-color: rgba(1,1,1,1);
}

.carousel-control-next-icon:hover {
  background-color: rgba(1,1,1,1);
}

///////////////////////////////////////////////////////
////////////////////-----Other-----////////////////////
///////////////////////////////////////////////////////

@media (max-width: 768px) {
  .collapsible .accordion .card_body--align{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .collapsible .accordion .carousel-textarea {
    padding-left: 0;
  }
}

@media (max-width: 991.98px) {
  .collapsible .accordion .card .fictional-button {
    width: 30px;
    height: 20px;
  }

  .collapsible .accordion .card .title {
    width: 270px;
    max-height: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .hidetext {
    display: none;
    overflow: hidden;
  }
}

@media (max-width: 575.98px) {
  .collapsible .accordion .card .title {
    width: 170px;
    max-height: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

// Mildly irritating bootstrap things
.card-header:first-child {
    border-radius: $border-radius;
}

///////////////////////////////////////////////////////
///////////-----Collapsible-with-button-----///////////
///////////////////////////////////////////////////////

.collapsible.with-button .accordion .card .card-header .groupup {
  display: flex;
  align-items: center;
}

.collapsible.with-button .accordion .card button {
  width: 140px;
  margin-right: 35px;
  z-index: 999;
}
