
///////////////////////////////////////////////////////
/////////////////-----Card flight-----/////////////////
///////////////////////////////////////////////////////

.extras-list-items {
  border-radius: $border-radius;
  margin-bottom: 30px;
  overflow: hidden;
  border: 1px solid $border-grey;
}

.extras-list-items .item  {
  width: 100%;
  height: 60px;
  background-color: $light;
  border-radius: 0;
  border-bottom: none;
  padding: $gutter;
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
}

.extras-list-items .item:nth-child(odd) {
  background-color: $gray-300;
}


.extras-list-items .item .groupup {
  display: flex;
  flex: 1;
  align-items: center;
}

.extras-list-items .item .groupup button {
  width: 140px;
  margin-right: 35px;
}

.extras-list-items .item .groupup .bag-item {
  display: flex;
  flex: 1;
  align-items: baseline;
}

.extras-list-items .item .groupup .title {
  margin-right: 20px;
  font-size: $h3-font-size;
  font-weight: $font-weight-normal;
}

.extras-list-items .item .groupup .dimensions {
  font-size: $font-size-lg;
  font-weight: $font-weight-normal;
}

.headertitle {
  font-size: $h3-font-size;
  font-weight: $font-weight-bold;
}

.price {
  margin-left: 35px;
  font-size: $h2-font-size;
  font-weight: $font-weight-bold;
}

///////////////////////////////////////////////////////
///////////////////-----total box-----/////////////////
///////////////////////////////////////////////////////

.total-box {
  width: 100%;
  height: 60px;
  background-color: #73818C;
  border-radius: $border-radius;
  margin-bottom: 30px;
  margin-top: -20px;
  padding: 15px;
  color: $light;

  display: flex;
  align-items: center;
  justify-content: space-between;
}



///////////////////////////////////////////////////////
////////////////-----Media queries-----////////////////
///////////////////////////////////////////////////////


@include media-breakpoint-down(sm) {
  .headertitle {
    font-size: 1rem;
    max-height: 48px;
    overflow: hidden;
  }

  .extras-list-items .item .groupup .title {
    margin-right: 10px;
    font-size: 1rem;
    max-height: 48px;
    overflow: hidden;
  }

  .extras-list-items .item .groupup button {
    margin-right: 10px;
  }

  .price {
    margin-left: 10px;
    font-size: 1rem;
  }
}


@include media-breakpoint-down(sm) {
  .headertitle {
    font-size: 0.8rem;
    max-height: 40px;
    overflow: hidden;
  }

  .extras-list-items .item .groupup .title {
    margin-right: 10px;
    font-size: 0.8rem;
    max-height: 40px;
    overflow: hidden;
  }

  .extras-list-items .item .groupup button {
    margin-right: 10px;
  }

  .price {
    margin-left: 10px;
    font-size: 0.8rem;
  }
}
