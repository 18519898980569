
///////////////////////////////////////////////////////
///////////////////-----Footer-----////////////////////
///////////////////////////////////////////////////////

.footer .nav-text {
  font-size: 0.75rem;
  color: black;
  display: block;
  padding: 0.5rem 1rem;
}

.footer .nav-link {
  font-size: 0.75rem;
  color: black;
}

.footer .nav-link:hover {
  color: $primary;
}

.footer .icon-wrapper {
  display: flex;
  padding-left: 6px;
  padding-top: 5px;
}

.footer .icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 20px;

  img {
    width: 20px;
    height: 20px;
  }
}

.footer {
  padding-top: 5.188rem;
  padding-bottom: 5.188rem;
  background-color: #fff;
}



///////////////////////////////////////////////////////
////////////////-----Media queries-----////////////////
///////////////////////////////////////////////////////

@include media-breakpoint-down(md) {
  .footer .nav-title {
    text-align: center;
  }

  .footer .nav-text {
    text-align: center;
  }

  .footer .nav-link {
    text-align: center;
  }

  .footer .icon-wrapper {
    align-items: center;
    justify-content: center;

    margin-left: -10px;
    padding-left: 0px;
  }
}