/**
 * Define custom variables here
 */

$font-family-sans-serif: Avenir_font, times;
$font-family-serif:      Avenir_font, times;
$font-family-monospace:  Avenir_font, times;
$font-family-base:       $font-family-sans-serif !default;

///////////////////////////////////////////////////////
////////////////-----Color codes-----//////////////////
///////////////////////////////////////////////////////

$blue:                #56959F !default; //primary color
$lightblue:           #95BBC1 !default;
$teal:                #82A3A8 !default;
$darkteal:            #6E8A8F !default;
$greycyan:            #E5E8EA !default;
$white:               #ffffff !default;
$deepblue:            #444C56 !default;
$black:               #000000 !default;
$blackgrey:           #444C56 !default;
$darkblue:            #000A18 !default;

$border-grey:                #A3A6AC !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":           $blue,
    "lightblue":      $lightblue,
    "teal":           $teal,
    "darkteal":       $darkteal,
    "greycyan":       $greycyan,
    "white":          $white,
    "deepblue":       $deepblue,
    "black":          $black,
    "blackgrey":      $blackgrey,
    "darkblue":       $darkblue
  ),
  $colors
);

$primary:             $blue !default;
$hover:               $teal !default;
$more:                $darkteal !default;
$tertiary:            $greycyan !default;
$light:               $white !default;
$label:               $deepblue !default;
$dark:                $black !default;
$darkgrey:            $blackgrey !default;
$darktext:            $darkblue !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":        $primary,
    "hover":          $hover,
    "tertiary":       $tertiary,
    "light":          $light,
    "label":          $label,
    "dark":           $dark,
    "darkgrey":       $darkgrey,
    "darktext":       $darktext
  ),
  $theme-colors
);

$font-weight-light:                 100 !default;
$font-weight-normal:                200 !default;
$font-weight-medium:                400 !default;
$font-weight-bold:                  600 !default;
$font-weight-extrabold:             700 !default;

$link-color:                        $primary;
//$link-hover-color:                  #56959F; //green

$border-radius:                     0.375rem !default;
$input-btn-border-width:            0rem !default;

$input-btn-padding-y:               .375rem !default;
$input-btn-padding-x:               .75rem !default;
$btn-font-weight:                   $font-weight-bold;

//Text
$font-size-base:                    0.875rem !default;
$font-size-xl:                      1.188rem !default;
$font-size-lg:                      1rem !default;
$font-size-sm:                      0.75rem !default;

$h1-font-size:                      3.25rem !default;
$h2-font-size:                      1.5rem !default;
$h3-font-size:                      1.125rem !default;
$h4-font-size:                      1.25rem !default;


$image-font-size:                   1.375rem !default;
$h5-font-size:                      2.938rem !default;


$headings-font-weight:              600 !default;

@media (max-width: 768px) {
  $h2-font-size:                    1.125rem !default;
}

///////////////////////////////////////////////////////
//////////////////-----Grid-----/////////////////////
///////////////////////////////////////////////////////

$gutter:                  15px;



///////////////////////////////////////////////////////
//////////////////-----Navbar-----/////////////////////
///////////////////////////////////////////////////////

$navbar-padding-y:                  2.157rem;
$navbar-dark-color:                $light !default;
$navbar-dark-hover-color:          $light !default;
$navbar-dark-active-color:         $light !default;

$navbar-padding-y:                  2.157rem;
$navbar-light-color:                $primary !default;
$navbar-light-hover-color:          $hover !default;
$navbar-light-active-color:         $more !default;

///////////////////////////////////////////////////////
////////////////-----Breadcrumb-----///////////////////
///////////////////////////////////////////////////////

$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              1rem !default;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     none !default;
$breadcrumb-divider-color:          $dark !default;
$breadcrumb-active-color:           $darkgrey !default;
$breadcrumb-divider:                quote("/") !default;

$breadcrumb-border-radius:          $border-radius !default;

///////////////////////////////////////////////////////
//////////////////-----Tooltips-----///////////////////
///////////////////////////////////////////////////////

$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $more !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   1 !default;
$tooltip-padding-y:                 .25rem !default;
$tooltip-padding-x:                 .5rem !default;
$tooltip-margin:                    0 !default;

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;

///////////////////////////////////////////////////////
///////////////////-----Table-----/////////////////////
///////////////////////////////////////////////////////

thead tr {
  border: 0;
  background-color: $navbar-light-active-color;
  border-radius: $border-radius;
}

tbody {
  background-color: #fff;
}

tr:nth-child(odd) {
  background: #EAECEE;
}
