
///////////////////////////////////////////////////////
///////////////////-----Tour Review-----///////////////
///////////////////////////////////////////////////////

.tour-review-name {
  margin-top: 10px;

  font-weight: 300 !important;
  color: #000;
  font-size: 1.2em;
}