/*
|--------------------------------------------------------------------------
| Loading dots
|--------------------------------------------------------------------------
*/

@keyframes blink {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}


.loader span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}


.loader span:nth-child(2) {
  animation-delay: .2s;
}


.loader span:nth-child(3) {
  animation-delay: .4s;
}
