
///////////////////////////////////////////////////////
////////////////////-----Form-----/////////////////////
///////////////////////////////////////////////////////

.form-group label {
  font-size: $font-size-sm;
  color: $darktext;
  font-weight: $font-weight-light;
}

.form-group ::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder { /* Firefox 18- */
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

.form-group input {
  background-color: $tertiary;
  color: $darktext;
  height: 44px;
}

.form-group input, ::placeholder {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  color: $darktext;
}

.form-group input::placeholder {
  font-style: italic;
}

.form-group select {
  background-color: $tertiary;
}

.form-group textarea {
  background-color: $tertiary;
  color: $darktext;
}

select[multiple] {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

.form-group .selectinput {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  background-color: $tertiary;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  padding-right: 15px;
  background-position: right 10px top 14px;
  background-repeat: no-repeat;
  background-image: url(/../../images/selecter_icon.svg);
}

/* CAUTION: IE hackery ahead */
.form-group .selectinput::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
    .form-group .selectinput {
        background:none\9;
        padding: 5px\9;
    }
}

.hekla-select {
  height: 44px;

  padding-left: 20px;

  background-color: $tertiary;

  font-weight: $font-weight-bold;
  color: $darktext;
  line-height: 1.5;
}
