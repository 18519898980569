///////////////////////////////////////////////////////
/////////////////-----Blockquote-----//////////////////
///////////////////////////////////////////////////////

.blockquote {

}

.blockquote h3 {
  margin-bottom: 10px;
}

.blockquote-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.blockquote-footer::before {
    content: none;
}

cite {
  color: $dark;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  font-style: normal;
  margin-left: 10px;
}

@media (max-width: 310px) {
  cite {
    margin-left: 0px;
    margin-top: 10px;
  }
}
