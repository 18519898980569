.ui-dropdown {
  position: relative;
}

.ui-dropdown__content {
  background-color: #FFF;
  position: absolute;
  min-width: 100%;
  border-radius: 6px;
  top: 100%;
  margin-top: 6px;
  box-shadow: 0 1px 5px 0 rgba(0,10,24,0.12);
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 100;
}

/**
 * Button
 **/

.ui-dropdown__button {
  background-color: #E5E8EA;
  border-radius: 6px;
  width: 100%;
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #000;
  transition: none;

  &:hover {
    cursor: pointer;

    .button__label {
      color: #fff;
    }

    svg {
      fill: #FFF;
    }
  }

  // Placeholder
  &.has-placeholder:not(:hover) {
    .button__label {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.ui-dropdown__button__label {
  font-weight: 600;
}

.ui-dropdown__button__triangle {
  position: absolute;
  right: 12px;
  top: 50%;
  fill: #73818C;
  transform: translateY(-50%);

  svg {
    width: 12px;
    transition: transform 0.2s;
  }
}

.button__triangle--flipped svg {
  transform: rotate(180deg);
}

.ui-dropdown__button__icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  svg {
    fill: #73818C;
    width: 24px;
    height: 24px;
  }
}