/*
|--------------------------------------------------------------------------
| Admin
|--------------------------------------------------------------------------
*/

.admin-section_header {
}


.admin-field_label {
  border-bottom: 1px solid #ccc;
}


.admin-item-row {
  margin: 0px 0px 10px 0px !important;

  padding-bottom: 5px;

  border-bottom: 1px solid #ccc;

  .photo-row {
    height: 150px;
  }
}


.admin-item-box {
  margin: 0px 0px 10px 0px !important;

  padding: 10px;

  border: 1px solid #ccc;
}


.admin-redirect-actions {
  min-width: 150px;
}


.admin-tour-photo-photo {
  max-height: 150px;
}

.admin-tour-photo-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
