
///////////////////////////////////////////////////////
////////////////-----Step by step-----/////////////////
///////////////////////////////////////////////////////

.stepbystep {
  width: 100%;
  height: 90px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.stepbystep .step-wrapper {
  width: 130px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stepbystep .step {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: $primary;
  color: $light;
  font-size: $h2-font-size;
  font-weight: $font-weight-extrabold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.step-text {
  width: auto;
  min-height: 30px;

  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  text-align: center;
  position: absolute;
  top: 60px;
}

.step .step-text {
  border-bottom: 2px solid $primary;
}

.stepbystep .step--outline {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: $light;
  border: 3px solid $primary;
  color: $primary;
  font-size: $h2-font-size;
  font-weight: $font-weight-extrabold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.stepbystep .step-text {
  color: $primary;
  font-weight: $font-weight-medium;
}

.stepbystep .border-wrapper {
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
}

.stepbystep .border-wrapper .border-line {
  width: 100%;
  height: 1px;
  border: 1px solid $primary;
}
