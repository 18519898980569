/*
|--------------------------------------------------------------------------
| Tour Detail Map
|--------------------------------------------------------------------------
*/


#map {
  width: 100%;
  height: 500px;

  margin-left: 15px;
  margin-right: 15px;
}
