
///////////////////////////////////////////////////////
//////////////////-----Buttons-----////////////////////
///////////////////////////////////////////////////////

// primary button //

.btn-primary, .btn-secoundary, .btn-danger, .btn-dark, .btn-light, .btn-more {
  padding: 0.5625rem 0.9375rem;
}

.btn-primary:hover {
  background-color: $hover;
}

.btn-outline-primary {
  color: $primary;
  font-weight: $font-weight-bold;
  background-color: transparent;
  border: 0.1875rem solid $primary;
}

.btn-outline-primary:hover {
  background-color: $hover;
  border: 0.1875rem solid $hover;
}

.btn-outline-danger {
  color: $danger;
  font-weight: $font-weight-bold;
  background-color: transparent;
  border: 0.1875rem solid $danger;
}

// bottom more button //

.btn-outline-more {
  color: $more;
  font-weight: $font-weight-bold;
  background-color: transparent;
  border: 0.1875rem solid $more;
}

.btn-outline-more:hover {
  color: #fff;
  font-weight: $font-weight-bold;
  background-color: $hover;
  border: 0.1875rem solid $hover;
}

// negative button //

.btn-outline-negative{
  color: $light;
  font-weight: $font-weight-bold;
  background-color: transparent;
  border: 0.1875rem solid $light;
}

.btn-outline-negative:hover {
  color: $dark;
  font-weight: $font-weight-bold;
  background-color: $light;
  border: 0.1875rem solid $light;
}

// cancel button //

.btn-light {
  color: $more;
  background-color: $light;
}

.btn-light:hover {
  color: $light;
  background-color: $hover;
}

// see more button //

.btn-see-more {
  color: $dark;
  font-weight: $font-weight-normal;
  background-color: transparent;
  background-position: right top 0.5625rem;
  background-repeat: no-repeat;
  background-image: url(/../../images/see_more_icon.svg);
  padding-right: 1.063rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-image 0.15s ease-in-out;
}

.btn-see-more:hover {
  color: $hover;
  background-image: url(/../../images/see_more_icon_hover.svg);
}
