
///////////////////////////////////////////////////////
/////////////////-----landing-page-thumb-----//////////////////
///////////////////////////////////////////////////////

.landing-page-thumb .curve-text {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;
}

.landing-page-thumb {
  width: 100%;
  background-color: #E9EDEE;
  border-radius: $border-radius;
  margin-bottom: 30px;
}

.landing-page-thumb .image-container {
  padding-bottom: 100%;
  width: 100%;
  position: relative;
}

.landing-page-thumb .image-container .img {
  display:flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  top:0;
  bottom:0;
  left:0;
  right:0;

  background-color: $tertiary;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  overflow:hidden;
  // filter: brightness(50%);

  border-radius: $border-radius;

  color: $light;
  display: flex;
  flex-direction: column;
}


.pseudo-background-img-container {
  position: relative;
  width: 100%;
  height: 100%;
}


.pseudo-background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



///////////////////////////////////////////////////////
////////////////-----Media queries-----////////////////
///////////////////////////////////////////////////////

@include media-breakpoint-only(md) {
  .landing-page-thumb h5 {
    font-size: 2rem;
  }
}