
///////////////////////////////////////////////////////
////////////////-----Card Result-----//////////////////
///////////////////////////////////////////////////////

.cardresult {
  width: 100%;
  min-height: 272px;
  max-height: 272px;
  background-color: #fff;
  border-radius: $border-radius;
  margin-bottom: 30px;
  display: flex;
  cursor: pointer;
}

.cardresult .content {
  height: 100%;
  padding: 30px 0 30px 0;
}


.cardresult .content p {
  height: 70px;
  margin: 0;
  overflow: hidden;
}

//fixes//

.col-8.addingrelative {
  position: relative;;
}

.cardresult .btn-primary {
  width: 100%;
}

/////////////////

//Elements//

.cardresult .pricetag {
  display: inline;
  height: 24px;
  font-size: $h2-font-size;
  font-weight: $font-weight-extrabold;
  margin-bottom: 15px;
}

.calltoaction {
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.cardresult .bestseller {
  width: 100px;
  height: 23px;
  font-size: 0.75rem;
  background-color: #95BBC1;
  border-radius: $border-radius;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  right: 15px;
}

.cardresult .reviews {
  margin-top: 45px;
}

/////////////////

.cardresult .image-container .like_button {
  width: 40px;
  height: 40px;
  background-image: url(/../../images/icons/like.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.cardresult .image-container .like_button:hover {
  cursor: pointer;
  background-image: url(/../../images/icons/like_white.svg);
}

.cardresult .image-container .like_button_liked {
  width: 40px;
  height: 40px;
  background-image: url(/../../images/icons/like_white.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.cardresult .image-container .like_button_liked:hover {
  cursor: pointer;
  background-image: url(/../../images/icons/like_white.svg);
}

//Image settings//

.cardresult .row {
  height:100%;
}

.cardresult .image-container {
  height:100%;
  position: relative;
  //border:1px solid yellow;
  width:calc(100% + 15px);
  margin-left: -$gutter;
}

.cardresult .image-container .img { //used for cropping image
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: $tertiary;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  overflow:hidden;
  display:flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

@media (max-width: 768px) {

  .cardresult .content p {
    height: 68px;
    margin: 0;
    overflow: hidden;
  }

  .cardresult {
    width: 100%;
    min-height: auto;
    max-height: 576px;
  }

  .cardresult .content {
    height: 100%;
    padding: 65px 0 30px 0;
  }

  .cardresult .bestseller {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .cardresult .pricetag {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  //Image settings//

  .cardresult .image-container {
    height: 250px;
    position: relative;
    //border:1px solid yellow;
    width:calc(100% + 30px);


  }

  .cardresult .image-container .img {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: 0;
  }

  ///////////////////

  //Review modifications//

  .cardresult .reviews {
    width: 110px;
    margin: 0;
    position: absolute;
    top: -45px;
    right: 15px;
  }

  .cardresult .reviews__text {
    display: none;
  }

  ////////////////////////

}
