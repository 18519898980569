
///////////////////////////////////////////////////////
////////////////-----tour-detail-----/////////////////
///////////////////////////////////////////////////////

.tour-detail-header {
  padding-top: 30px;
}

.tour-top-header {
  padding-top: 10px;
}

.tour-detail-button-bar {
  padding-top: 30px;
}

.multi-item-carousel {
  overflow: hidden;
}

.multi-item-carousel .carousel-inner {
  width: 100%;
}

.item-side {
  float: left;
  width: 5%;
  height: 600px;
  overflow: hidden;
}

.item-middle {
  float: left;
  width: 86%;
  height: 600px;
  margin-left: 2% !important;
  margin-right: 2% !important;
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
    display: block;
    margin: 0 auto;
    text-align: center;
  }
}

.single-image {
  height: 600px;
  width: auto;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.carousel-control-next {
  right: -5%;
}

.carousel-control-prev {
  left: -5%;
}




/*
|--------------------------------------------------------------------------
| Booking
|--------------------------------------------------------------------------
*/

.tour-booking-header {
  padding-top: 10px;
}


label.tour-booking-label {
  display: block;

  min-height: 14px;

  margin-top: 20px;

  font-size: 17px;
  font-weight: 400;

  &.top {
    margin-top: 0px;
  }
}


.universal-booking-calendar-available-date {
  span {
    color: #fff !important;

    background: $primary;

    width: 32px !important;
    height: 32px !important;
    // font-size: 14px;
    line-height: 1;
    margin: 3px auto;
    padding: 9px 0;

    border-radius: 50%;
  }

  span:hover {
    color: #fff !important;

    background: $primary;

    width: 32px !important;
    height: 32px !important;
    // font-size: 14px;
    line-height: 1;
    margin: 3px auto;
    padding: 9px 0;

    border-radius: 50%;

    cursor: pointer !important;
  }
}


.universal-booking-calendar-loader-prompt {
  margin-top: 10px;

  font-size: 1rem;
  font-weight: 500;
}


.universal-booking-pickup-dropoff-title {
  margin-top: 20px;
}


.universal-booking-pickup-dropoff-info {
  margin: 0px;
  padding: 0px;
}

.universal-booking-book-button {
  height: 44px;
  width: 100%;

  margin-top: 20px;
  margin-bottom: 20px;
}






///////////////////////////////////////////////////////
////////////////-----Media queries-----////////////////
///////////////////////////////////////////////////////

@include media-breakpoint-down(lg) {
  .item-side {
    height: 533px;
  }

  .item-middle {
    height: 533px;
  }

  .single-image {
    height: 533px;
  }
}


@include media-breakpoint-down(md) {
  .item-side {
    height: 396px;
  }

  .item-middle {
    height: 396px;
  }

  .single-image {
    height: 396px;
  }

  .carousel-control-next {
    right: -3%;
  }

  .carousel-control-prev {
    left: -3%;
  }
}


@include media-breakpoint-down(sm) {
  .item-side {
    height: 292px;
  }

  .item-middle {
    height: 292px;
  }

  .single-image {
    height: 292px;
  }

  .carousel-control-next {
    right: 0%;
  }

  .carousel-control-prev {
    left: 0%;
  }
}


@include media-breakpoint-down(xs) {
  .item-side {
    height: 220px;
  }

  .item-middle {
    height: 220px;
  }

  .single-image {
    height: 220px;
  }

  .carousel-control-next {
    right: 0%;
  }

  .carousel-control-prev {
    left: 0%;
  }
}
