
///////////////////////////////////////////////////////
////////////////-----Button group-----/////////////////
///////////////////////////////////////////////////////

.btn-group {
  display: flex;
  width: 100%;
  height: 60px;
  background-color: $light;
  border-radius: $border-radius;
  border: 3px solid #73818C;
  margin-bottom: 30px;
}

.btn-group .btn-buttongroup {
  width: 100%;
  border-right: 3px solid #73818C;
  background-color: $light;
  color: $dark;
  font-size: 0.8rem;
  padding: 0px;
  font-weight: $font-weight-bold;
}

.btn-group .btn.btn-buttongroup {
  border-radius: 0;
}

.btn-group .btn-buttongroup:hover {
  background-color: #73818C;
  color: $light;
}

.btn-group a {
  text-decoration: none;
}

.btn-group a:last-child .btn-buttongroup {
  border-right: none;
}

.btn.btn-buttongroup.active {
  color: $light;
  background-color: #73818C;
}
