
///////////////////////////////////////////////////////
/////////////////-----Card flight-----/////////////////
///////////////////////////////////////////////////////

.cardflight {
  width: 100%;
  //min-height: 180px;
  background-color: $light;
  border-radius: $border-radius;
  overflow: hidden;
  margin-bottom: 30px;

  display: flex;
  flex-direction: row;

  position: relative;
}

.travelprice {
  min-width: 110px;
  min-height: 100%;
  background-color: $lightblue;
  color: $light;
  font-size: $h2-font-size;
  font-weight: $font-weight-bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardflight__wrapper{
  width: 100%;
  height: 100%;
  padding: 0 60px 0 15px;
}

.cardflight__content {
  width: 100%;
  height: 50%;
  padding: 15px 0;

  display: flex;
  flex-direction: column;
}

.cardflight__item {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}

.logo-airline {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.logo-airline__logo {
  min-width: 50px;
  min-height: 50px;
  background-color: black;
  border-radius: $border-radius;
  margin-right: 15px;
}

.logo-airline__text {
  font-size: $h3-font-size;
  font-weight: $font-weight-medium;
}

.cardflight__item--mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.time-date__time {
  font-size: $h4-font-size;
  font-weight: $font-weight-bold;
}

.time-date__spacer {
  font-size: $h4-font-size;
  font-weight: $font-weight-bold;
  margin: 0 6px;
}

.time-date__date {
  font-size: $h3-font-size;
  font-weight: $font-weight-medium;
}

.open-close-arrow{
  width: 20px;
  height: 20px;
  background-image: url(/../../images/arrow_down.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
}

.estimate-stop {
  min-width: 150px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.estimate-stop__estimate {
  color: $primary;
  font-size: $h4-font-size;
  font-weight: $font-weight-bold;
}

.estimate-stop__stops {
  width: 150px;
  color: $primary;
  font-size: $h3-font-size;
  font-weight: $font-weight-light;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.arrow {
  width: 64px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/../../images/icons/flight_arrow.svg);

}

.cardflight__content--stop {
  height: 0px;
  display: none;
}

.title-collapsible {
  height: 0px;
  display: none;
}

.layover {
  width: 100%;
  height: 37px;
  background-color: #F0F1F3;
  border-radius: $border-radius;

  color: $primary;
  font-size: $h3-font-size;
  font-weight: 600;

  display: flex;
  align-items: center;
  padding-left: 187px;
  margin-bottom: 30px;
  margin-top: 15px;
}

.booking-button {
  height: 0px;
  display: none;
}

.booking-button .btn-primary {
  width: 100%;
}

.button-main-text {
  display: block;
}

.button-main-text--change {
  display: none;
}

///////////////////////////////////////////////////////
///////////-----Card flight collapsible-----///////////
///////////////////////////////////////////////////////

.cardflight.open .cardflight__content--stop {
  display: block;
  height: auto;
}

.cardflight.open .title-collapsible {
  display: block;
  height: auto;
}

.cardflight.open .open-close-arrow {
  background-image: url(/../../images/arrow_up.svg);
}

.cardflight.open .booking-button {
  padding: 15px 0;
  height: auto;
  display: block;
}

///////////////////////////////////////////////////////
////////////////-----Media queries-----////////////////
///////////////////////////////////////////////////////

@media (min-width: 768px) {
  .cardflight__content {
    border-bottom: 1px solid #EFF0F1
  }
}

@media (max-width: 1199.98px) {

  .cardflight__item--mobile {
    display: flex;
    align-items: center;
  }

  .time-date__date {
    display: none;
  }

  .layover {
    padding-left: 185px;
  }

}


@media (max-width: 991.98px) {
  .open-close-arrow {
    display: none;
  }

  .cardflight__wrapper {
    padding: 0 15px 0 15px;
  }

  .cardflight__item {
    display: flex;
    flex-direction: column;
  }

  .logo-airline {
    margin-bottom: 15px;
  }

  .logo-airline__logo {
    min-width: 20px;
    min-height: 20px;
  }

  .cardflight .booking-button {
    padding: 15px 0;
    height: auto;
    display: block;
  }

  .layover {
    padding-left: 0px;
    justify-content: center;
  }
}


@media (max-width: 767.98px) {

  .cardflight__item--mobile {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .cardflight .time-date .left {
    position: absolute;
    right: 0;
    top: 0px;
  }

  .cardflight .time-date .right {
    position: absolute;
    left: 0;
    top: 0px;
  }

  .cardflight .estimate-stop {
    min-width: 100%;
  }

  .cardflight .estimate-stop__stops {
    min-width: 100%;
    padding-left: 57px;
    padding-right: 55px;
  }

  .cardflight__content:last-child() {
    border-bottom: none;
  }

  .cardflight__content:first-child() {
    border-bottom: 1px solid #EFF0F1
  }
}


@media (max-width: 575.98px) {
  .travelprice {
    display: none;
  }

  .button-main-text {
    display: none;
  }

  .button-main-text--change {
    display: block;
  }

  .cardflight__item {
    display: flex;
    flex-direction: column;
    margin: 0px 0;
  }

  .logo-airline {
    margin-bottom: 30px;
  }
}
