/**
 * Page: Tour
 **/

/**
 * Header
 **/

.page-tour-header {
  background: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(115,129,140,0.12);
  padding: 20px 0 30px 0;
}

.page-tour-header-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .title-box {
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      margin-bottom: 0px;

      font-size: 36px;
      font-weight: 600;
    }

    .partner {
      font-size: 12px;
    }

    .badge {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: 10px;
      padding: 5px 20px;

      background-color: $primary;
      border-radius: $border-radius;

      font-size: 0.75rem;
      color: #fff;
      text-decoration: none;

      &.purple {
        background-color: #dd3074;
      }

      &.orange {
        background-color: #ff6b00;
      }
    }
  }

  .reviews-box {
    display: flex;
    flex-direction: column;

    .reviews-count {
      margin-top: 5px;
      text-align: right;
    }
  }
}


/**
 * Booking Section
 **/
.page-tour__booking {
  display: flex;
  align-items: flex-end;
  margin-top: 30px;

  .form-group {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.page-tour__booking__dropdown {
  flex: 1;
  max-width: 250px;
}

.page-tour__booking__dropdown__button {
  flex: 200px;
  flex: 225px;
  height: 44px;
  flex-grow: 0;
  margin-left: auto;
}


.mapboxgl-control-container {
  &:after {
    z-index: 2;
  }
}

.map-marker {
  background-image: url(/../../graphics/components/map_marker.svg);;
  background-size: cover;
  width: 50px;
  height: 50px;
}


.sticky-booking {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  height: 100vh;

  overflow-x: hidden;
}

.sticky-booking-overflow {
  overflow: auto;

  overflow-x: hidden;
}

