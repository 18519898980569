.img-container {
  position: relative;
  display: inline-block;

  margin-top: -30px;
  margin-bottom: 30px;

  width: 100%;

  img {
    width: 100%;
  }
}

.img-container .overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.overlay h1 {
  position: absolute;

  top: 50%;
  left: 50%;

  transform:translate(-50%, -50%);

  color: #fff;
  font-size: 3rem;
  font-weight: 500;
}

.overlay h3 {
  position: absolute;

  top: 50%;
  left: 10%;
  right: 10%;

  transform:translate(0%, -50%);

  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}