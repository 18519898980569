@import "../base/variables";

.toggle-switch {
  background-color: #E5E8EA;
  min-height: 44px;
  display: inline-flex;
  align-items: center;
  border-radius: 22px;
  justify-content: space-between;
  width: 80px;
  position: relative;
  padding: 10px;
  margin: 0;

  &:active {
    box-shadow: 0 0 0 0.2rem rgba(86, 149, 159, 0.5);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.toggle-switch__inner {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-switch__label--off {
  color: rgba(0, 0, 0, 0.5);
}

.toggle-switch__label {
  font-weight: 600;
  font-size: 14px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s;
  position: relative;
  padding: 0 5px;
  z-index: 10;  
}

.toggle-switch__circle {
  position: absolute;
  border-radius: 999px;
  background-color: #FFF;
  width: 28px;
  height: 28px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid rgba(115,129,140,0.20);
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.12);
  z-index: 20;
  transition: transform 0.1s, background-color 0.07s;
}

.toggle-switch__input {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; /* added line */
  width: 1px;
}

// Off
.toggle-switch__input:not(:checked) + .toggle-switch__circle {
  transform: translate(0, -50%);

  + .toggle-switch__label--on + .toggle-switch__label--off {
    opacity: 1;
  }
}  

// On
.toggle-switch__input:checked + .toggle-switch__circle {
  transform: translate(calc(120%), -50%);
  background-color: $primary;

  + .toggle-switch__label--on {
    opacity: 1;
  }
}