
/*
|--------------------------------------------------------------------------
| Booking Dialog
|--------------------------------------------------------------------------
*/

.booking-dialog-title {
  font-size: 1.8rem;
}
