
///////////////////////////////////////////////////////
/////////////////-----Card-image-----//////////////////
///////////////////////////////////////////////////////

.card-image .curve-text {
  font-size: $image-font-size;
  font-weight: 500;
  font-style: italic;
}

.card-image {
  width: 100%;
  background-color: #E9EDEE;
  border-radius: $border-radius;
  margin-bottom: 30px;
}

.card-image .image-container {
  padding-bottom: 100%;
  width: 100%;
  position: relative;
}

.card-image .image-container .img {
  display:flex;
  justify-content: center;
  align-items: center;

  position:absolute;

  top:0;
  bottom:0;
  left:0;
  right:0;

  background-color: $tertiary;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  overflow:hidden;

  border-radius: $border-radius;

  color: $light;
  display: flex;
  flex-direction: column;
}


.pseudo-background-img-container {
  position: relative;
  width: 100%;
  height: 100%;
}


.pseudo-background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



///////////////////////////////////////////////////////
////////////////-----Media queries-----////////////////
///////////////////////////////////////////////////////

@include media-breakpoint-only(md) {
  .card-image h5 {
    font-size: 2rem;
  }
}